
var gDataTablesFixmaster = function() {

  var dt = this;

  dt._tables = [
    'table',
    'tableNew',
    'tableProcessing',
    'tableComplete',
    'tableFailed',
  ];

  dt.table = $('#fixmasters').DataTable({
    lengthChange: false,
    order: [[ 0, 'desc' ]],
  });

  dt.tableNew = $('#fixmasters-new').DataTable({
    lengthChange: false,
    order: [[ 0, 'desc' ]],
  });

  dt.tableProcessing = $('#fixmasters-processing').DataTable({
    lengthChange: false,
    order: [[ 0, 'desc' ]],
  });

  dt.tableComplete = $('#fixmasters-complete').DataTable({
    lengthChange: false,
    order: [[ 0, 'desc' ]],
  });

  dt.tableFailed = $('#fixmasters-failed').DataTable({
    lengthChange: false,
    order: [[ 0, 'desc' ]],
  });

  dt.removeRowFromTable = function(table, row_id) {
    dt[table].row('.row-' + row_id).remove().draw();
  }

  dt.removeRowFromTables = function(row_id) {
    $.each(dt._tables, function(idx, table) {
      dt.removeRowFromTable(table, row_id);
    });
  }
};
